const useMysteryBoxesController = () => {
	const { data, execute } = useBonusesData();
	const { open } = useAppModals();
	const { playMysteryBoxAudio } = useMysteryBoxAudios();
	const { data: balanceData, execute: executeBalanceData } = useGetBalanceData();
	const { state } = usePopupsState();

	const handleOpen = () => {
		open("LazyOModalMysteryBox");
		playMysteryBoxAudio();
	};

	const handleAsyncOpen = async () => {
		await execute();
		await executeBalanceData();
		if (
			data.value?.dailyChests?.visible &&
			(data.value?.dailyChests?.available ||
				data.value?.dailyChests?.reasonType === "phone" ||
				data.value?.dailyChests?.reasonType === "time" ||
				(data.value?.dailyChests?.reasonType === "balance" && balanceData.value?.entriesBalance))
		) {
			handleOpen();

			dispatchGAEvent({
				event: "click_button",
				step: "start",
				form_name: "spin_win",
				location: state.value.isLoginAfterRegistration ? "login" : "registation"
			});
		}
	};

	return {
		handleOpen,
		handleAsyncOpen
	};
};

export default useMysteryBoxesController;
