<script setup lang="ts">
const { handleSupportButtonClick, handleSupportButtonMouseover, enabled } = useSupportChat();
const ready = useTimeout(1000);
</script>

<template>
	<AButton
		v-if="ready && enabled"
		id="support-chat-btn"
		aria-label="Chat support"
		class="support"
		variant="primary"
		type="button"
		data-tid="support-chat"
		:modifiers="['circle']"
		@click="handleSupportButtonClick"
		@mouseover="handleSupportButtonMouseover"
	>
		<NuxtIcon name="20/chat-support" />
	</AButton>
</template>

<style lang="scss" scoped>
.jackpotRabbit:root {
	.support {
		border-radius: 10px;
	}
}
button.support {
	right: 16px;
	bottom: 16px;
	position: fixed;
	width: 64px;
	height: 64px;
	z-index: 2000;
	font-size: 38px;
	color: var(--support-chat-color, --chemnitz);
	background: var(--support-chat-background);
	border: none;

	&:hover {
		color: var(--support-chat-color-hover);
		background: var(--support-chat-background-hover);
	}

	@include media-breakpoint-down(lg) {
		display: none;
	}
}
</style>
<style lang="scss">
/* Zendesk hack */
div > div:nth-child(2) > iframe[title] {
	@include media-breakpoint-down(lg) {
		display: none;
	}
}
</style>
