import type { MaybeElementRef } from "@vueuse/core";

const useAndroidFullscreenToggles = (target?: MaybeElementRef) => {
	const { enter, exit, isSupported, isFullscreen, toggle } = useFullscreen(target);
	const { isMobile, isAndroid } = useDevice();
	const { isOpen } = useAppModals();
	const isFullscreenState = useState("isFullscreen", () => false);

	const exitFullscreen = () => {
		if (isSupported.value && isMobile && isAndroid) {
			if (isFullscreen.value !== isFullscreenState.value) {
				isFullscreen.value = isFullscreenState.value;
			}
			exit().catch((err) => console.error(`[${err}]: Error when trying to exit full screen mode`));
			isFullscreenState.value = false;
		}
	};

	const enterFullscreen = () => {
		if (isSupported.value && isMobile && isAndroid && isOpen("LazyOModalGame")) {
			enter().catch((err) => console.error(`[${err}]: Error when trying to enter full screen mode`));
			isFullscreenState.value = true;
		}
	};

	return {
		exitFullscreen,
		enterFullscreen,
		toggle,
		isFullscreen,
		isSupported
	};
};

export default useAndroidFullscreenToggles;
