import type { AsyncDataOptions } from "#app";

import type { v1, ExtractFromAPI } from "@netgame/openapi";

import useAppInitData from "./useAppInitData";
import useAsyncFetch from "./useAsyncFetch";

type TournamentResponse = Required<ExtractFromAPI<v1.paths, "/rest/tournament/", "get">>;

const useGetTournamentData = ({
	immediate = true,
	cached = true,
	gameId = ref()
}: AsyncDataOptions<TournamentResponse> & {
	gameId?: Ref<number | undefined>;
	cached?: true | boolean;
} = {}) => {
	const { data: appInitData } = useAppInitData();

	return useAsyncFetch({
		path: "/rest/tournament/",
		method: "get",
		options: {
			immediate,
			cached: !cached ? undefined : true,
			watch: [() => appInitData.value?.gameMode],
			transform: (data) => ({ ...data, isActiveStatus: !Array.isArray(data?.data) })
		},
		fetchOptions: () => ({
			query: {
				gameId: gameId.value
			}
		})
	});
};

export default useGetTournamentData;
