import dayjs from "dayjs";

const useRabbitDepositStreak = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const isGuest = useIsGuest();
	const { data: appInit } = useAppInitData();
	const { close } = useAppModals();
	const { refresh: refreshPromotionsData, refreshHomeData } = usePromotionsData();
	const { data: depositInfoData, refresh } = useGetDepositStreakData({ immediate });

	const { durationLeft, reset } = useCountdown({
		timestamp: depositInfoData.value.finishedAt ?? "",
		formatToken:
			dayjs(depositInfoData.value.finishedAt ?? "").diff(dayjs(), "d") >= 1
				? "DD[ d ]HH[ h ]mm[ m]"
				: "HH[ h ]mm[ m ]ss[ s]",
		onCountdownStop: () => {
			if (appInit.value?.depositStreak?.isActive && !isGuest.value) {
				appInit.value.depositStreak.isActive = false;
				close("LazyOModalDepositStreak");
				refreshHomeData();
				refreshPromotionsData();
			}
		}
	});

	const { durationLeft: durationLeftResetStreak, reset: resetDurationStreak } = useCountdown({
		timestamp: depositInfoData.value?.resetStreakAt ?? "",
		formatToken: "HH[h ]mm[m ]ss[s ]",
		onCountdownStop: !isGuest.value ? refresh : () => {}
	});

	if (process.client) {
		watch(
			() => depositInfoData.value.finishedAt,
			(value) => {
				reset(value || "", "DD[ d ]HH[ h ]mm[ m]");
			}
		);

		watch(
			() => depositInfoData.value.resetStreakAt,
			(value) => {
				resetDurationStreak(value || "", "HH[h ]mm[m ]ss[s ]");
			}
		);
	}

	return { depositInfoData, refresh, durationLeft, durationLeftResetStreak };
};

export default useRabbitDepositStreak;
