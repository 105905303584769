const prependSlash = (str?: string) => {
	if (!str) {
		return str;
	}
	return str.startsWith("/") ? str : `/${str}`;
};

const useBonusesData = () => {
	const { data } = useAppInitData();
	const bonusesFetch = useAsyncFetch({
		path: "/rest/bonus-offer/get-bonuses/",
		method: "get",
		options: {
			key: "bonusesOffer",
			immediate: data.value?.isGuest === false,
			watch: [() => data.value?.isGuest],
			cached: true,
			transform: (data) => {
				const isMagicBoxAvailable = !!(
					data.dailyChests?.available ||
					data.dailyChests?.reasonType === "phone" ||
					data.dailyChests?.reasonType === "balance"
				);
				return {
					...data,
					isMagicBoxAvailable,
					audios: [
						{
							name: "soundActiveChests",
							mp3: prependSlash(data.dailyChests?.soundActiveChests?.[0]?.mp3),
							ogg: prependSlash(data.dailyChests?.soundActiveChests?.[0]?.ogg),
							arguments: {
								loop: true
							}
						},
						{
							name: "soundDisabledChests",
							mp3: prependSlash(data.dailyChests?.soundDisabledChests?.[0]?.mp3),
							ogg: prependSlash(data.dailyChests?.soundDisabledChests?.[0]?.ogg),
							arguments: {
								loop: true
							}
						},
						{
							name: "soundOpenChest",
							mp3: prependSlash(data.dailyChests?.soundOpenChest?.[0]?.mp3),
							ogg: prependSlash(data.dailyChests?.soundOpenChest?.[0]?.ogg),
							arguments: {}
						}
					] as const
				};
			}
		}
	});

	return bonusesFetch;
};

export default useBonusesData;
