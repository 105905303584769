const useEventsCollector = () => {
	const showMagicRoulette = computed(() => {
		const currentDateTimeUTC = new Date();

		const startDateNY = new Date(Date.UTC(2024, 3, 2, 7, 0, 0));
		const endDateNY = new Date(Date.UTC(2024, 3, 6, 7, 0, 0));

		return currentDateTimeUTC >= startDateNY && currentDateTimeUTC < endDateNY;
	});

	return {
		showMagicRoulette
	};
};

export default useEventsCollector;
