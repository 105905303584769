<script lang="ts" setup>
const { t } = useT();
const handleError = () => clearError({ redirect: "/" });
</script>

<template>
	<div>
		<NuxtLayout>
			<div class="error">
				<NuxtImg src="/nuxt-img/error/ladder.png" alt="404" class="ladder" format="webp" width="272" height="187" />
				<NuxtImg src="/nuxt-img/error/404.png" alt="404" class="error-img" format="webp" width="264" height="126" />

				<div class="content">
					<AText class="title text-tlalnepantla" variant="taylor" :modifiers="['center', 'normal']" as="h2">{{
						t("Rabbit not found")
					}}</AText>
					<AButton variant="primary" class="home-back" size="xl" @click="handleError">
						<AText variant="turin" :modifiers="['medium']">{{ t("Go Home") }}</AText>
					</AButton>
				</div>
			</div>
		</NuxtLayout>
		<ClientOnly>
			<SupportChat />
			<OModals />
		</ClientOnly>
	</div>
</template>

<style lang="scss" scoped>
.error {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 104px);
}
.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
}

.home-back {
	padding: 0 24px;
	box-shadow: 0 3px 32px 0 var(--chhatarpur) !important;
}

.title {
	position: relative;
}

.build {
	position: absolute;
	bottom: 0;
}

.ladder {
	margin-bottom: 40px;
}

.error-img {
	max-width: 100%;
	height: auto;
	object-fit: cover;

	@include media-breakpoint-down(md) {
		width: 150px;
		height: 58px;
	}
}
</style>
