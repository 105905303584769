const useAppBingo = () => {
	const { data: appInit } = useAppInitData();
	const isGuest = useIsGuest();
	const loginGuard = useLoginGuard();
	const { isOpen, open, close } = useAppModals();
	const { openModalOnce, checkDailyOpen } = useWelcomeModal(open, close);
	const { bingoData, updateBingoData } = useBingoData({ immediate: false });

	const handleShowPopups = async () => {
		if (isGuest.value || !appInit.value?.bingo?.isActive) {
			return;
		}
		await updateBingoData();
		openModalOnce({
			storage: "showBingoWelcome",
			storageSkip: "showBingoFeature",
			modal: "LazyOModalBingoWelcome",
			endDate: bingoData.value?.bingoEndDate
		});
		if (!isOpen("LazyOModalBingoWelcome")) {
			checkDailyOpen({ storage: "showBingoFeature", modal: "LazyOModalBingoProgress" });
		}
	};

	if (process.client) {
		watch([isGuest, () => appInit.value?.bingo?.isActive], ([newGuest, newBingo]) => {
			if (!newGuest && newBingo) {
				handleShowPopups();
			}
		});
	}

	onMounted(() => {
		loginGuard({
			success: () => {
				handleShowPopups();
			}
		});
	});
};

export default useAppBingo;
