<script setup lang="ts">
const { open, close, isOpen } = useAppModals();
const loginGuard = useLoginGuard();
const { data: appInit, refresh: refreshAppInitData } = useAppInitData();
const isGuest = computed(() => appInit.value?.isGuest);
const { refresh: refreshHomeData } = useHomePage({ immediate: false });
const { refresh: refreshBonusesData } = useBonusesData();
const route = useRoute();
const { t } = useT();
const { enterFullscreen } = useAndroidFullscreenToggles();
const { checkDailyOpen } = useWelcomeModal(open, close);
const { refresh: refreshDepositStreak } = useRabbitDepositStreak();

onMounted(() => {
	pushNotificationsDispatchEvent("init");

	if (route.query.game) {
		open("LazyOModalGame");
	}
	if (route.query?.action === "register") {
		setTimeout(() => {
			open("LazyOModalWelcome");
		}, 1000);
	}
	if (!isGuest.value && !appInit?.value?.emailConfirmed && !route.query.game) {
		open("LazyOModalEmailConfirm");
	}

	loginGuard({
		success: () => {
			if (appInit.value?.depositStreak?.isActive && !isOpen("LazyOModalWelcome")) {
				checkDailyOpen({ storage: "showDepositStreakWelcome", modal: "LazyOModalDepositStreakWelcome" });
			}
		}
	});
});

useEvent(["nuxt:openLogin"], () => {
	open("LazyOModalLogin");
});
useEvent(["nuxt:openSignup"], () => {
	const { isOneClickRegister } = useOtpGetFlow();

	if (isOneClickRegister) {
		open("LazyOModalOneClickSignup");
		return;
	}
	open("LazyOModalSignup");
});
useEvent(["nuxt:openOneClickComplete"], () => {
	open("LazyOModalOneClickSignupComplete");
});
useEvent(["nuxt:closeSignup"], () => {
	close("LazyOModalSignup");
});
useEvent(["nuxt:openEmailConfirm"], (event: Event) => {
	const { detail } = event as CustomEvent;
	open("LazyOModalEmailConfirm", { immediateRequest: detail.immediateRequest });
});
useEvent(["nuxt:closeEmailConfirm"], () => {
	close("LazyOModalEmailConfirm");
});
useEvent(["nuxt:openPhoneConfirmation"], (event: Event) => {
	const { detail } = event as CustomEvent;
	open("LazyOModalPhoneConfirmation", detail);
});
useEvent(["nuxt:openRacesGames"], () => {
	open("LazyOModalRacesGames");
});
useEvent(["nuxt:closeRacesGames"], () => {
	close("LazyOModalRacesGames");
});
useEvent(["nuxt:openJackpotsGames"], () => {
	open("LazyOModalJackpotsGames");
});
useEvent(["nuxt:setWelcomePopup"], (event: Event) => {
	const { detail } = event as CustomEvent;
	if (detail.isOpen) {
		open("LazyOModalWelcome");
	} else {
		close("LazyOModalWelcome");
	}
});
useEvent(["nuxt:refreshHomeData"], () => {
	refreshHomeData();
});
useEvent(["nuxt:playerInit"], () => {
	refreshAppInitData();
});
useEvent(["nuxt:toggleRegistrationCompletePopup"], (event) => {
	if ((event as CustomEvent).detail.isOpen === true) {
		open("LazyOModalRegistrationComplete");
		return;
	}
	close("LazyOModalRegistrationComplete");
});
useEvent(["nuxt:openMoneyBox"], () => {
	open("LazyOModalMoneyBox");
});
useEvent(["nuxt:openFlipTheCoin"], () => {
	open("LazyOModalFlipTheCoin");
});
useEvent(["nuxt:openFlipTheCoinUsed"], () => {
	open("LazyOModalFlipTheCoinUsed");
});
useEvent(["nuxt:openTermsDialog"], (event) => {
	open("LazyOModalTermsDialog", {
		type: (event as CustomEvent).detail.type,
		anchor: (event as CustomEvent).detail.anchor
	});
});

useEvent(["OPEN_PHONE_VERIFY_POPUP"], () => {
	loginGuard({
		success: () => {
			open("LazyOModalPhoneConfirmation");
		}
	});
});
useEvent(["LEAVE_CASH"], () => {
	enterFullscreen();
});
useEvent(["UPDATE_NUXT_DATA"], () => {
	loginGuard({
		success: () => {
			refreshBonusesData();
		}
	});
});
useEvent(["nuxt:depositStreakRefresh"], () => {
	refreshDepositStreak();
});
useEvent(["nuxt:depositStreakHiW"], () => {
	open("LazyOModalDepositStreakHowItWorks");
});
useEvent(["nuxt:openDepositStreak"], () => {
	open("LazyOModalDepositStreak");
});

useAppBingo();
useBingoSocket();

useInitRegisterComplete();
usePopupsInit();
usePresetPackageInit();
useCheckPromoOffer(open);
useAppRefreshSocket(t);
useExitModal();
useUploadDocumentsToast("dark");
usePiggySocket(open);
</script>
<template>
	<div>
		<NuxtPage />
		<ClientOnly>
			<SupportChat />
			<OModals />
		</ClientOnly>
	</div>
</template>

<style lang="scss">
::-webkit-scrollbar {
	width: 4px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: var(--сirebon);
}
.Toastify {
	&__toast {
		@include media-breakpoint-down(lg) {
			margin-bottom: 8px;
		}
	}

	&__toast-container {
		@media (max-width: 480px) {
			top: 1em;
		}
	}

	&__toast:has(.badge) {
		overflow: visible;
	}

	.icon-checked {
		background-image: url(/assets/icons/24/success.svg);
	}

	.icon-close {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
		background: url("~/assets/icons/20/close.svg") center/cover no-repeat;
	}

	.badge {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 10px;
		left: -13px;
		width: 26px;
		height: 20px;
		border-radius: gutter(0.5);
		color: var(--coro);
		font-size: 12px;
		background-color: var(--camaguey);
		z-index: 123123;
	}
}

#referralModal,
#change-password,
#promo-code-success {
	.Toastify {
		&__toast {
			padding: 12px;
			border-radius: 12px;
			border: 2px solid var(--chomutov);
			background: var(--coari);
			box-shadow: 0px 30px 40px -10px var(--chusovoy);

			&-icon {
				width: 40px;
				height: 40px;

				.toast-icon.icon-checked {
					background-image: url(/assets/icons/40/toast-success.svg);
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	.refferal-text {
		font-weight: 400;
	}
}

#promo-code-success {
	.Toastify {
		&__toast-body {
			font-weight: 400;
			color: var(--neutral-100);
		}
		&__toast {
			&-icon {
				background: var(--success-900);
				border-radius: 50%;
				.toast-icon.icon-checked {
					background-size: 20px;
					background-image: url(/assets/icons/40/toast-promo-success.svg);
				}
			}
		}
	}
}

#phone-verification-success {
	z-index: 10005;
	.Toastify {
		&__toast-body {
			font-weight: 400;
			color: var(--neutral-100);
		}
		&__toast {
			border: 2px solid var(--success-800);
			background: var(--success-950);
			&-icon {
				width: 40px;
				height: 40px;
				background: var(--success-900);
				border-radius: 50%;
				.toast-icon.icon-checked {
					width: 40px;
					height: 40px;
					background-size: 20px;
					background-image: url(/assets/icons/40/toast-phone-success.svg);
				}
			}
		}
	}
}

#email-confirm-container {
	width: 320px;
	max-width: 100%;

	@include media-breakpoint-down(md) {
		transform: translateX(-50%);
		left: 50%;
		top: 8px;
	}

	.Toastify {
		&__toast-body {
			color: var(--clara);
		}
	}
}

#notification-center-container {
	width: 328px;
	max-width: 100%;

	@include media-breakpoint-down(lg) {
		bottom: auto;
		left: auto;
		top: 64px;
	}

	.icon-close {
		flex-shrink: 0;
		width: 18px;
		height: 18px;
		background: url("~/assets/icons/20/close.svg") center/cover no-repeat;
	}

	.notification {
		&-center {
			display: flex;
			flex-direction: column;
		}

		&-title {
			color: var(--coro);
			font-weight: 600;
			line-height: 21px;
		}

		&-text {
			color: var(--cadiz);
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
		}

		&-more {
			color: var(--changchun);
		}
	}

	.Toastify {
		&__close-button {
			background: url("~/assets/icons/20/close.svg") center/cover no-repeat;
			width: 20px;
			height: 20px;
			opacity: 1;

			&:hover {
				opacity: 0.7;
			}

			svg {
				display: none;
			}
		}
		&__toast {
			border-radius: 8px;
			border: 1px solid var(--cracow);
			background-color: var(--carletonville);
			padding: gutter(2);
			min-height: 65px;
		}
		&__toast-body {
			color: var(--cadiz);
		}
		&__toast-icon {
			width: 36px;
			margin-right: gutter(1);

			img {
				width: 36px;
				height: 36px;
			}
		}

		&__toast-body > div:last-child > div {
			display: flex;
			flex-direction: column;
		}
	}
}
#notification-quest-progress {
	--toastify-toast-width: 265px;

	@include media-breakpoint-down(lg) {
		bottom: auto;
		left: 12px;
		top: 76px;
		max-width: 265px;

		@media (orientation: landscape) {
			top: 12px;
			left: 60px;
		}
	}

	.Toastify {
		&__toast-body {
			flex-direction: row-reverse;
			align-items: flex-start;
			color: var(--clara);

			& > div:last-child {
				font-weight: 500;
				text-transform: uppercase;
				color: var(--cannes);
			}
		}

		&__toast-icon {
			margin: 0;
			width: auto;

			.icon-close {
				flex-shrink: 0;
				width: 12px;
				height: 12px;
				background: url("~/assets/icons/20/close.svg") center/cover no-repeat;
			}
		}
	}
}

#upload-document-notification,
#upload-document-cash-notification {
	.Toastify {
		&__toast-icon {
			position: absolute;
			top: 16px;
			right: 4px;
		}
	}

	.Toastify__toast {
		min-width: 328px;
		width: 328px;
		height: auto;
		padding: 16px;
		background: var(--carletonville);

		.Toastify__toast-body {
			white-space: normal;
			margin: 0;
		}

		.Toastify__close-button {
			display: none;
		}

		.toast-content-container {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.toast-img-container {
			display: flex;
			align-items: center;
		}

		.toast-img {
			width: 40px;
			height: 40px;
		}

		.toast-text {
			padding: 0 8px 0 16px;
			line-break: auto;
			white-space: pre-line;
		}

		.toast-title {
			font-size: 14px;
			font-weight: 600;
			line-height: 1.6;
			color: var(--clara);
		}

		.toast-message {
			color: var(--carpio);
			font-size: 12px;
			font-weight: 300;
			line-height: 16px;
			padding-top: 8px;
		}
	}

	@media (max-width: 480px) {
		display: flex;
		justify-content: center;
		top: 1em;
	}
}

#toggle-favorite {
	& #add,
	#remove {
		display: flex;
		align-items: center;
		padding: 12px;
		height: 64px;
		border-radius: 12px;
		font-size: 14px;
		font-weight: 400;
		color: var(--neutral-100);

		@media (max-width: 480px) {
			max-width: calc(100% - 16px);
			top: 1em;
		}

		.Toastify__toast-icon {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	#add {
		border: 2px solid var(--success-800);
		background: var(--success-950);

		.Toastify__toast-icon {
			background: var(--success-800);
		}
	}

	#remove {
		border: 2px solid var(--neutral-800);
		background: var(--neutral-950);

		.Toastify__toast-icon {
			background: var(--neutral-800);
		}
	}
}

#level-info {
	.Toastify {
		&__toast {
			border-radius: 8px;
			border: 2px solid var(--neutral-800);
			background-color: var(--carletonville);
			padding: gutter(2);
			min-height: 65px;
		}

		&__toast-body {
			font-weight: 400;
			color: var(--cadiz);
		}

		&__toast-icon {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background: var(--neutral-800);
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.icon-checked {
		background-image: url(/assets/icons/24/copy.svg);
		background-size: contain;
	}
}
</style>
