<script setup lang="ts">
import type { HTMLProps } from "../types-utils";

const props = withDefaults(
	defineProps<
		{
			bgColor?: string;
			modifiers?: Array<"blur" | "flex-center" | "flex-start" | "fixed" | "auto" | "circle">;
			hideOverflow?: boolean;
		} & HTMLProps
	>(),
	{ bgColor: "var(--a-overlay)", hideOverflow: false }
);

onMounted(() => {
	if (props.hideOverflow) {
		document.body.classList.add("overflow-hidden");
		// zE && zE("messenger:set", "zIndex", 0);
	}
});

onUnmounted(() => {
	if (props.hideOverflow) {
		document.body.classList.remove("overflow-hidden");
		// zE && zE("messenger:set", "zIndex", 2000);
	}
});
</script>

<template>
	<div :class="['overlay', ...(modifiers || [])].filter(Boolean)">
		<slot />
	</div>
</template>

<style lang="scss">
:root {
	--a-overlay: var(--cochin);
	--a-overlay-cursor: pointer;
}

@if mixin-exists(AOverlay) {
	@include AOverlay;
}
</style>

<style lang="scss" scoped>
.overlay {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: v-bind(bgColor);
	cursor: var(--a-overlay-cursor);

	@media (hover: hover) {
		display: block;
	}

	&.circle {
		transition: all 0.35s ease-in-out;
		background: radial-gradient(farthest-side, transparent calc(100% - 200px), var(--chandigarh) 0) center / 600px 600px
			no-repeat;

		@media (hover: hover) {
			&:hover {
				background-size: 400px 400px;
			}
		}
	}

	&.fixed {
		position: fixed;
	}

	&.auto {
		overflow: auto;
	}

	&.blur {
		backdrop-filter: blur(var(--a-card-game-locked-blur));
		@supports (backdrop-filter: blur(var(--a-card-game-locked-blur))) or
			(-webkit-backdrop-filter: blur(var(--a-card-game-locked-blur))) {
			background-color: var(--a-card-game-locked-background);
			backdrop-filter: blur(var(--a-card-game-locked-blur));
		}
	}

	&.flex-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.flex-start {
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}

	&:has(.modal.top-center) {
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}

	&:has(.modal.center) {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&:has(.modal.top-right) {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}
}
</style>
