import type { PromotionsResponse, Promotion } from "~/types";

import dayjs from "dayjs";

const selectPrizeEntries = (promotion: Promotion) => {
	if (promotion.type === "quest") {
		return promotion.data?.grandPrize?.entries;
	}
	return promotion.data?.prizeEntries;
};

const selectPrizeCoins = (promotion: Promotion) => {
	if (promotion.type === "quest") {
		return promotion.data?.grandPrize?.coins;
	}
	return promotion.data?.prizeCoins;
};

const selectPrizeUsualPrice = (promotion: Promotion) => {
	if (!promotion.data?.promoOfferPreset?.usualPrice) {
		return;
	}
	return Number(promotion.data?.promoOfferPreset?.usualPrice).toFixed(2);
};

const getSecretOffer = (promotion: Promotion) =>
	["offerDeposit", "offerBirthday"].includes(promotion.type || "") &&
	promotion?.data?.promoOfferPreset?.forCompleteProfile;

const selectBadgeLabel = (promotion: Promotion) => parseJSON(promotion.data?.promoOfferPreset?.badgeLabel || "{}");

const selectPackage = ({
	entries,
	coins,
	howWorks,
	promotion,
	t
}: {
	entries: number;
	coins: number;
	howWorks: string;
	promotion: Promotion;
	t: ReturnType<typeof useT>["t"];
}) => {
	const packages = {
		inviteQualification: {
			title: t("Invite friends program"),
			entries,
			coins,
			type: "inviteQualification",
			imagePopup: "/nuxt-img/promotions/offer-qualify.png",
			imagePopupMobile: "/nuxt-img/promotions/offer-qualify.png",
			subTitle: t("Qualify to Get Rewards!"),
			howWorks: ""
		},
		verification: {
			title: t("Verification"),
			entries: 1000,
			coins: 0,
			type: "verification",
			imagePopup: "/nuxt-img/promotions/offer_verification.png",
			imagePopupMobile: "/nuxt-img/promotions/offer_verification.png",
			subTitle: "Secure your account",
			howWorks: ""
		},
		invite: {
			title: t("Share the Reward!"),
			entries,
			coins,
			type: "invite",
			imagePopup: "/nuxt-img/promotions/invite-friend.webp",
			imagePopupMobile: "/nuxt-img/promotions/invite-friend.webp",
			subTitle: t("Invite a Friend"),
			howWorks
		},
		magicBox: {
			title: t("Mystery box"),
			type: "magicBox",
			imagePopup: "/nuxt-img/promotions/offer-mystery-box.png",
			imagePopupMobile: "/nuxt-img/promotions/offer-mystery-box.png",
			subTitle: t("Get max prize"),
			entries: 500,
			coins: 275000,
			howWorks: ""
		},
		quest: {
			title: t("Your Daily mission is"),
			entries,
			coins,
			type: "quest",
			imagePopup: "/nuxt-img/promotions/offer-quest.png",
			imagePopupMobile: "/nuxt-img/promotions/offer-quest.png",
			subTitle: t("Prize Pool"),
			howWorks: ""
		},
		piggyBank: {
			title: t("Piggy Bank"),
			entries: 0,
			coins: 0,
			type: "piggyBank",
			imagePopup: "/nuxt-img/promotions/piggy.jpg",
			imagePopupMobile: "/nuxt-img/promotions/piggy.jpg",
			subTitle: "",
			howWorks: ""
		},
		offerDeposit: {
			title: "",
			entries: 0,
			coins: 0,
			type: "offerDeposit",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		offerPeriodic: {
			title: "",
			entries: 0,
			coins: 0,
			type: "offerDeposit",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		flipTheCoin: {
			title: t("Mystery Dragon"),
			entries: 0,
			coins: 0,
			type: "flipTheCoin",
			imagePopup: "/nuxt-img/promotions/flip-coin.png",
			imagePopupMobile: "/nuxt-img/promotions/flip-coin.png",
			subTitle: "",
			howWorks: ""
		},
		rankLeague: {
			title: t("VIP club members only"),
			entries: 350000,
			coins: 5000,
			type: "rankLeague",
			imagePopup: "/nuxt-img/vip/promo-card/vip-club.png",
			imagePopupMobile: "/nuxt-img/vip/promo-card/vip-club.png",
			subTitle: t("Get max prize"),
			howWorks: ""
		},
		rankLeagueReward: {
			title: t("VIP club rewards"),
			entries: 0,
			coins: 0,
			type: "rankLeagueReward",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: t("Get up to"),
			howWorks: ""
		},
		dailyWheel: {
			title: "",
			entries: 0,
			coins: 0,
			type: "dailyWheel",
			imagePopup: "/nuxt-img/promotions/wheel-large.jpg",
			imagePopupMobile: "/nuxt-img/promotions/wheel-mobile.jpg",
			subTitle: t("Wheel of Fortune"),
			howWorks: ""
		},
		tournament: {
			title: t("Races"),
			entries: 0,
			coins: 0,
			type: "tournament",
			imagePopup: "/nuxt-img/promotions/tournament.webp",
			imagePopupMobile: "/nuxt-img/promotions/tournament.webp",
			subTitle: t("Prize Pool"),
			howWorks: ""
		},
		depositStreak: {
			title: "",
			entries: 0,
			coins: 0,
			type: "depositStreak",
			imagePopup: "/nuxt-img/deposit-streak/promo.webp",
			imagePopupMobile: "/nuxt-img/deposit-streak/promo.webp",
			subTitle: "",
			howWorks: ""
		}
	};
	if (promotion.data?.promoOfferPreset) {
		return promotion.data?.promoOfferPreset;
	}
	const packageType = (promotion.type || "magicBox") as keyof typeof packages;
	if (!(packageType in packages)) {
		return;
	}
	return packages[packageType];
};

const selectTooltipText = ({ t, promotion }: { t: ReturnType<typeof useT>["t"]; promotion: Promotion }) => {
	const daysOfWeek = promotion.data?.promoOfferPreset?.dayOfWeek || [];
	const hasOneDay = daysOfWeek.length === 1;
	if (promotion.data?.needDeposits) {
		return t("Available after the th purchase", { msg: promotion.data?.needDeposits });
	}
	if (promotion.data?.promoOfferPreset?.isLimitPerDayReason) {
		if (!hasOneDay) {
			return t("Can be claimed again next weekends.");
		}
		const index = parseInt(daysOfWeek[0]);
		return t("Available after the", { days: dayjs().day(index).format("dddd") });
	}
	if (promotion.data?.promoOfferPreset?.isMinBalanceReason) {
		return t("Can be claimed again when your total entries + winnings + pending redeems are less than.", {
			msg: promotion.data?.promoOfferPreset.minBalance
		});
	}
	if (hasOneDay) {
		const index = parseInt(daysOfWeek[0]);
		return t("Available on", { days: dayjs().day(index).format("dddd") });
	}
	return t("Available on weekends");
};

const selectHowWorks = (item: ReturnType<typeof selectPackage>) => {
	if (item === undefined) {
		return item;
	}
	return {
		...item,
		howWorks: parseJSON<Record<string, string | number>>(item?.howWorks || "{}")
	};
};

const hasPromotionInfoMap = {
	verification: true,
	inviteQualification: true,
	magicBox: true,
	quest: true
} as const;

const selectHasPromotionInfo = (promotion: Promotion) => {
	const type = promotion.type || "";
	return type in hasPromotionInfoMap;
};

const selectDate = (promotion: Promotion) =>
	dayjs().add(
		promotion.data?.promoOfferPreset?.availableTill ? Number(promotion.data?.promoOfferPreset?.availableTill) : 0,
		"second"
	);

const createDecorator = (t: ReturnType<typeof useT>["t"]) => (promotion: Promotion) => {
	const prizeEntries = selectPrizeEntries(toRaw(promotion));
	const prizeCoins = selectPrizeCoins(toRaw(promotion));
	const useualPrice = selectPrizeUsualPrice(toRaw(promotion));
	const badgeLabel = selectBadgeLabel(toRaw(promotion));
	const secretOffer = getSecretOffer(toRaw(promotion));

	const pkg = selectHowWorks(
		selectPackage({
			t,
			entries: prizeEntries || 0,
			coins: prizeCoins || 0,
			howWorks: promotion.data?.howWorks || "{}",
			promotion
		})
	);
	const hasPromotionInfo = selectHasPromotionInfo(promotion);
	const date = selectDate(promotion);
	const tooltipText = selectTooltipText({ t, promotion });
	return {
		...promotion,
		package: pkg,
		useualPrice,
		prizeEntries,
		prizeCoins,
		hasPromotionInfo,
		date,
		tooltipText,
		badgeLabel,
		secretOffer
	};
};

const normalize = (
	promotions: Array<Promotion>,
	packageDecorator: ReturnType<typeof createDecorator>,
	condition: (promotion: Promotion) => boolean
) =>
	promotions.reduce((accumulator: Array<Promotion>, promotion) => {
		if (condition(promotion)) {
			return accumulator;
		}
		return [...accumulator, packageDecorator(promotion)];
	}, []) as Array<ReturnType<typeof packageDecorator>>;

const transform = (packageDecorator: ReturnType<typeof createDecorator>) => (data: PromotionsResponse) => {
	const promotions = normalize(data.payload!.promotions!, packageDecorator, (promotion) => {
		if (promotion.type === "emailLottery") {
			return true;
		}
		if (promotion.type === "quest" && !promotion?.data?.available) {
			return true;
		}
		return false;
	});
	const offerBanner = promotions.find(({ type }) => type === "offerDeposit");
	const offer = offerBanner?.data?.promoOfferPreset;
	const availableSoon = normalize(data.payload!.available_soon as Array<Promotion>, packageDecorator, (_) => false);
	return {
		promotions,
		offerBanner,
		offer,
		availableSoon
	};
};

const defaultData = (): ReturnType<ReturnType<typeof transform>> => ({
	promotions: Array(3).fill({
		skeleton: true
	}),
	offerBanner: {
		type: "offerDeposit",
		skeleton: true,
		prizeCoins: 0,
		prizeEntries: 0,
		useualPrice: "",
		package: undefined,
		hasPromotionInfo: false,
		date: dayjs(),
		tooltipText: "",
		badgeLabel: {}
	},
	offer: {},
	availableSoon: Array(4).fill({
		skeleton: true
	})
});

const usePromotionsData = () => {
	const { t } = useT();
	const { data: appInit } = useAppInitData();
	const isGuest = useIsGuest();
	const { promotions, refresh: refreshHomeData } = useHomePage();
	const { showMagicRoulette } = useEventsCollector();
	const { tournamentData } = useRace();

	const isProfileCompleted = computed(() => {
		const { name, surname, birthDay, profileCompleted } = appInit?.value || {};
		return profileCompleted || !!(name && surname && birthDay);
	});

	const openDeposit = (promotion: Promotion) => {
		let url = "";

		if (promotion?.package.imagePopup) {
			url = promotion?.package.promoOfferPreset
				? `/cash/deposit-by-money/${promotion?.package.promoOfferPreset.id}/promoOffer/?inviteQualification=true`
				: `/cash/deposit-by-money/${promotion?.data?.package.preset.id}/preset/?inviteQualification=true`;
		} else {
			url = "/cash/deposit-by-money/";
		}

		window?.$cash?.$router?.push?.(url);
	};

	const openCash = (promotion: Promotion) => {
		const isD0User = appInit.value?.depositsCount === 0;
		const isVerificationCompleted = appInit.value?.fraudDetector?.verificationCompleted;
		const isFinOperationsAvailable =
			window?.$cash?.$store?.getters?.["cash/appCash"]?.finOperations?.isAvailable ?? false;

		const { applePayEnabled, lastPayProvider } = promotion?.data || {};
		const isShowLastPaymentMethod = lastPayProvider && lastPayProvider.method_id && lastPayProvider.type !== "applepay";
		const quickDealStr =
			!isD0User && isVerificationCompleted && isFinOperationsAvailable && (applePayEnabled || isShowLastPaymentMethod)
				? "?quick-cash=true"
				: "";

		window?.$cash?.$router?.push?.(
			`/cash/deposit-by-money/${promotion?.data?.promoOfferPreset?.id}/promoOffer/${quickDealStr}`
		);
	};

	const handleSecretOffer = (promotion: Promotion) => {
		if (isProfileCompleted.value) {
			openCash(promotion);
			return;
		}
		window?.$cash?.$router?.push("/cash/account/?isOutsideCash=true");
	};

	const magicBoxPromotion = selectPackage({
		t,
		entries: 0,
		coins: 0,
		howWorks: "",
		promotion: {
			type: "magicBox"
		}
	});
	const piggybankPromotion = selectPackage({
		t,
		entries: 0,
		coins: 0,
		howWorks: "",
		promotion: {
			type: "piggyBank"
		}
	});

	const homePromotions = computed(() => ({
		promotions: promotions.value
			.filter((el) => {
				if (el.type === "quest") {
					return el?.data?.available;
				}
				if (el.type === "tournament") {
					return tournamentData.value?.isActiveStatus;
				}
				if (el.type === "depositStreak") {
					return !appInit.value?.isGuest && appInit.value?.depositStreak?.isActive;
				}
				return true;
			})
			.map((el) => createDecorator(t)(el as Promotion))
	}));

	const counterHomePromo = computed(() => {
		const promo = promotions.value.filter((el) => el.type !== "dailyWheel");

		return promo.length > 9 ? "9+" : `${promo.length}`;
	});

	const { data: promoData, refresh } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: {
			server: false,
			immediate: !isGuest.value,
			watch: [() => isGuest.value]
		}
	});

	const promotionsData = computed(() => {
		if (!promoData.value) {
			return defaultData();
		}

		return transform(createDecorator(t))(promoData.value);
	});

	const promoPagePromotions = computed(() =>
		promotionsData.value?.promotions?.filter((el) => {
			if (el.type === "tournament") {
				return tournamentData.value?.isActiveStatus;
			}
			return true;
		})
	);

	return {
		refresh,
		refreshHomeData,
		promotionsData,
		promoPagePromotions,
		isProfileCompleted,
		homePromotions,
		openDeposit,
		handleSecretOffer,
		openCash,
		magicBoxPromotion: {
			...magicBoxPromotion,
			...(showMagicRoulette.value && {
				title: t("Lucky Roulette"),
				imagePopup: "/nuxt-img/promotions/offer-lucky-roulette.webp",
				imagePopupMobile: "/nuxt-img/promotions/offer-lucky-roulette.webp"
			}),
			textTooltip: t("Available on weekends")
		} as typeof magicBoxPromotion & { textTooltip: string },
		piggybankPromotion,
		counterHomePromo
	};
};

export default usePromotionsData;
