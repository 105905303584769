import LazyOModalAccountChangePassword from "~/organizms/OModal/AccountChangePassword.vue";
import LazyOModalAccountEditContact from "~/organizms/OModal/AccountEditContact.vue";
import LazyOModalAvatars from "~/organizms/OModal/Avatars.vue";
import LazyOModalBingoHowItWorks from "~/organizms/OModal/Bingo/HowItWorks.vue";
import LazyOModalBingoPlay from "~/organizms/OModal/Bingo/Play.vue";
import LazyOModalBingoProgress from "~/organizms/OModal/Bingo/Progress.vue";
import LazyOModalBingoWelcome from "~/organizms/OModal/Bingo/Welcome.vue";
import LazyOModalBingoWin from "~/organizms/OModal/bingo/Win.vue";
import LazyOModalCollectionsGames from "~/organizms/OModal/CollectionsGames.vue";
import LazyOModalDailyWheelFortuneWheel from "~/organizms/OModal/DailyWheel/FortuneWheel.vue";
import LazyOModalDailyWheelHowItWorks from "~/organizms/OModal/DailyWheel/HowItWorks.vue";
import LazyOModalDailyWheel from "~/organizms/OModal/DailyWheel/index.vue";
import LazyOModalDailyWheelRules from "~/organizms/OModal/DailyWheel/Rules.vue";
import LazyOModalDepositStreakHowItWorks from "~/organizms/OModal/DepositStreak/HowItWorks.vue";
import LazyOModalDepositStreak from "~/organizms/OModal/DepositStreak/Index.vue";
import LazyOModalDepositStreakWelcome from "~/organizms/OModal/DepositStreak/Welcome.vue";
import LazyOModalEmailConfirm from "~/organizms/OModal/EmailConfirm.vue";
import LazyOModalExit from "~/organizms/OModal/Exit.vue";
import LazyOModalFlipTheCoin from "~/organizms/OModal/FlipTheCoin.vue";
import LazyOModalFlipTheCoinUsed from "~/organizms/OModal/FlipTheCoinUsed.vue";
import LazyOModalForgotPassword from "~/organizms/OModal/ForgotPassword.vue";
import LazyOModalForgotPasswordFailed from "~/organizms/OModal/ForgotPasswordFailed.vue";
import LazyOModalForgotPasswordPhoneSent from "~/organizms/OModal/ForgotPasswordPhoneSent.vue";
import LazyOModalForgotPasswordSent from "~/organizms/OModal/ForgotPasswordSent.vue";
import LazyOModalGameSearch from "~/organizms/OModal/Game/Search.vue";
import LazyOModalGame from "~/organizms/OModal/Game.vue";
import LazyOModalGamePreview from "~/organizms/OModal/GamePreview.vue";
import LazyOModalGamesSwitchToEntries from "~/organizms/OModal/Games/SwitchToEntries.vue";
import LazyOModalInsufficientFunds from "~/organizms/OModal/InsufficientFunds.vue";
import LazyOModalInviteFriends from "~/organizms/OModal/InviteFriends.vue";
import LazyOModalJackpotsGames from "~/organizms/OModal/JackpotsGames.vue";
import LazyOModalLogin from "~/organizms/OModal/Login.vue";
import LazyOModalMoneyBox from "~/organizms/OModal/MoneyBox.vue";
import LazyOModalMysteryBox from "~/organizms/OModal/MysteryBox.vue";
import LazyOModalMysteryBoxHowItWorks from "~/organizms/OModal/MysteryBoxHowItWorks.vue";
import LazyOModalOneClickSignup from "~/organizms/OModal/OneClickSignup.vue";
import LazyOModalOneClickSignupComplete from "~/organizms/OModal/OneClickSignupComplete.vue";
import LazyOModalPhoneConfirmation from "~/organizms/OModal/PhoneConfirmation.vue";
import LazyOModalPhoneConfirmationInfo from "~/organizms/OModal/PhoneConfirmationInfo.vue";
import LazyOModalPiggyBankPrizeWin from "~/organizms/OModal/PiggyBank/PrizeWin.vue";
import LazyOModalPresetPackage from "~/organizms/OModal/PresetPackage.vue";
import LazyOModalPromoHowItWorks from "~/organizms/OModal/PromoHowItWorks.vue";
import LazyOModalQrcode from "~/organizms/OModal/Qrcode.vue";
import LazyOModalQuestsAlmostComplete from "~/organizms/OModal/Quests/AlmostComplete.vue";
import LazyOModalQuestsCompleted from "~/organizms/OModal/Quests/Completed.vue";
import LazyOModalQuestsHowItWorks from "~/organizms/OModal/Quests/HowItWorks.vue";
import LazyOModalQuests from "~/organizms/OModal/Quests/index.vue";
import LazyOModalQuestsLevelUp from "~/organizms/OModal/Quests/LevelUp.vue";
import LazyOModalQuestsRankUp from "~/organizms/OModal/Quests/RankUp.vue";
import LazyOModalRacesGames from "~/organizms/OModal/RacesGames.vue";
import LazyOModalRedeemHowItWork from "~/organizms/OModal/RedeemHowItWork.vue";
import LazyOModalReferralInfo from "~/organizms/OModal/ReferralInfo.vue";
import LazyOModalRegistrationComplete from "~/organizms/OModal/RegistrationComplete.vue";
import LazyOModalSignup from "~/organizms/OModal/Signup.vue";
import LazyOModalSignupExitPopup from "~/organizms/OModal/SignupExitPopup.vue";
import LazyOModalSuccessReferral from "~/organizms/OModal/SuccessReferral.vue";
import LazyOModalTermsDialog from "~/organizms/OModal/TermsDialog.vue";
import LazyOModalTournamentHowItWorks from "~/organizms/OModal/Tournament/HowItWorks.vue";
import LazyOModalTournamentLeaderboard from "~/organizms/OModal/Tournament/Leaderboard.vue";
import LazyOModalTournamentPrizes from "~/organizms/OModal/Tournament/Prizes.vue";
import LazyOModalVipClubGames from "~/organizms/OModal/VipClub/Games.vue";
import LazyOModalWelcome from "~/organizms/OModal/Welcome.vue";
import LazyOModalWinningsUserRegister from "~/organizms/OModal/WinningsUserRegister.vue";

type Modals = {
	LazyOModalForgotPassword: InstanceType<typeof LazyOModalForgotPassword>["$props"];
	LazyOModalForgotPasswordSent: InstanceType<typeof LazyOModalForgotPasswordSent>["$props"];
	LazyOModalLogin: InstanceType<typeof LazyOModalLogin>["$props"];
	LazyOModalSignup: InstanceType<typeof LazyOModalSignup>["$props"];
	LazyOModalOneClickSignup: InstanceType<typeof LazyOModalOneClickSignup>["$props"];
	LazyOModalOneClickSignupComplete: InstanceType<typeof LazyOModalOneClickSignupComplete>["$props"];
	LazyOModalSignupExitPopup: InstanceType<typeof LazyOModalSignupExitPopup>["$props"];
	LazyOModalEmailConfirm: InstanceType<typeof LazyOModalEmailConfirm>["$props"];
	LazyOModalExit: InstanceType<typeof LazyOModalExit>["$props"];
	LazyOModalAccountEditContact: InstanceType<typeof LazyOModalAccountEditContact>["$props"];
	LazyOModalAccountChangePassword: InstanceType<typeof LazyOModalAccountChangePassword>["$props"];
	LazyOModalPhoneConfirmation: InstanceType<typeof LazyOModalPhoneConfirmation>["$props"];
	LazyOModalPhoneConfirmationInfo: InstanceType<typeof LazyOModalPhoneConfirmationInfo>["$props"];
	LazyOModalRacesGames: InstanceType<typeof LazyOModalRacesGames>["$props"];
	LazyOModalRedeemHowItWork: InstanceType<typeof LazyOModalRedeemHowItWork>["$props"];
	LazyOModalRegistrationComplete: InstanceType<typeof LazyOModalRegistrationComplete>["$props"];
	LazyOModalTermsDialog: InstanceType<typeof LazyOModalTermsDialog>["$props"];
	LazyOModalGame: InstanceType<typeof LazyOModalGame>["$props"];
	LazyOModalInsufficientFunds: InstanceType<typeof LazyOModalInsufficientFunds>["$props"];
	LazyOModalPromoHowItWorks: InstanceType<typeof LazyOModalPromoHowItWorks>["$props"];
	LazyOModalInviteFriends: InstanceType<typeof LazyOModalInviteFriends>["$props"];
	LazyOModalPresetPackage: InstanceType<typeof LazyOModalPresetPackage>["$props"];
	LazyOModalWelcome: InstanceType<typeof LazyOModalWelcome>["$props"];
	LazyOModalFlipTheCoin: InstanceType<typeof LazyOModalFlipTheCoin>["$props"];
	LazyOModalFlipTheCoinUsed: InstanceType<typeof LazyOModalFlipTheCoinUsed>["$props"];
	LazyOModalJackpotsGames: InstanceType<typeof LazyOModalJackpotsGames>["$props"];
	LazyOModalMoneyBox: InstanceType<typeof LazyOModalMoneyBox>["$props"];
	LazyOModalMysteryBox: InstanceType<typeof LazyOModalMysteryBox>["$props"];
	LazyOModalQuests: InstanceType<typeof LazyOModalQuests>["$props"];
	LazyOModalQuestsHowItWorks: InstanceType<typeof LazyOModalQuestsHowItWorks>["$props"];
	LazyOModalQuestsAlmostComplete: InstanceType<typeof LazyOModalQuestsAlmostComplete>["$props"];
	LazyOModalQuestsCompleted: InstanceType<typeof LazyOModalQuestsCompleted>["$props"];
	LazyOModalQuestsLevelUp: InstanceType<typeof LazyOModalQuestsLevelUp>["$props"];
	LazyOModalQuestsRankUp: InstanceType<typeof LazyOModalQuestsRankUp>["$props"];
	LazyOModalDailyWheel: InstanceType<typeof LazyOModalDailyWheel>["$props"];
	LazyOModalDailyWheelRules: InstanceType<typeof LazyOModalDailyWheelRules>["$props"];
	LazyOModalDailyWheelFortuneWheel: InstanceType<typeof LazyOModalDailyWheelFortuneWheel>["$props"];
	LazyOModalDailyWheelHowItWorks: InstanceType<typeof LazyOModalDailyWheelHowItWorks>["$props"];
	LazyOModalGamesSwitchToEntries: InstanceType<typeof LazyOModalGamesSwitchToEntries>["$props"];
	LazyOModalVipClubGames: InstanceType<typeof LazyOModalVipClubGames>["$props"];
	LazyOModalMysteryBoxHowItWorks: InstanceType<typeof LazyOModalMysteryBoxHowItWorks>["$props"];
	LazyOModalTournamentLeaderboard: InstanceType<typeof LazyOModalTournamentLeaderboard>["$props"];
	LazyOModalTournamentPrizes: InstanceType<typeof LazyOModalTournamentPrizes>["$props"];
	LazyOModalTournamentHowItWorks: InstanceType<typeof LazyOModalTournamentHowItWorks>["$props"];
	LazyOModalAvatars: InstanceType<typeof LazyOModalAvatars>["$props"];
	LazyOModalGamePreview: InstanceType<typeof LazyOModalGamePreview>["$props"];
	LazyOModalCollectionsGames: InstanceType<typeof LazyOModalCollectionsGames>["$props"];
	LazyOModalReferralInfo: InstanceType<typeof LazyOModalReferralInfo>["$props"];
	LazyOModalSuccessReferral: InstanceType<typeof LazyOModalSuccessReferral>["$props"];
	LazyOModalWinningsUserRegister: InstanceType<typeof LazyOModalWinningsUserRegister>["$props"];
	LazyOModalForgotPasswordFailed: InstanceType<typeof LazyOModalForgotPasswordFailed>["$props"];
	LazyOModalForgotPasswordPhoneSent: InstanceType<typeof LazyOModalForgotPasswordPhoneSent>["$props"];
	LazyOModalPiggyBankPrizeWin: InstanceType<typeof LazyOModalPiggyBankPrizeWin>["$props"];
	LazyOModalQrcode: InstanceType<typeof LazyOModalQrcode>["$props"];
	LazyOModalBingoPlay: InstanceType<typeof LazyOModalBingoPlay>["$props"];
	LazyOModalBingoHowItWorks: InstanceType<typeof LazyOModalBingoHowItWorks>["$props"];
	LazyOModalBingoWelcome: InstanceType<typeof LazyOModalBingoWelcome>["$props"];
	LazyOModalBingoWin: InstanceType<typeof LazyOModalBingoWin>["$props"];
	LazyOModalBingoProgress: InstanceType<typeof LazyOModalBingoProgress>["$props"];
	LazyOModalGameSearch: InstanceType<typeof LazyOModalGameSearch>["$props"];
	LazyOModalDepositStreak: InstanceType<typeof LazyOModalDepositStreak>["$props"];
	LazyOModalDepositStreakWelcome: InstanceType<typeof LazyOModalDepositStreakWelcome>["$props"];
	LazyOModalDepositStreakHowItWorks: InstanceType<typeof LazyOModalDepositStreakHowItWorks>["$props"];
};

const useAppModals = () => useModals<Modals>();

export default useAppModals;
