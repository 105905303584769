import { default as indexBwaSHyWdZGMeta } from "/app/apps/jackpotrabbit/pages/affiliates/index.vue?macro=true";
import { default as partners_45accountjEX9zL1gx1Meta } from "/app/apps/jackpotrabbit/pages/affiliates/partners-account.vue?macro=true";
import { default as change_45passworduRBA0gBkFoMeta } from "/app/apps/jackpotrabbit/pages/change-password.vue?macro=true";
import { default as complete_45profileGfM2e59G1pMeta } from "/app/apps/jackpotrabbit/pages/complete-profile.vue?macro=true";
import { default as email_45editq3VahIHZKXMeta } from "/app/apps/jackpotrabbit/pages/email-edit.vue?macro=true";
import { default as email_45verifyOs4zgQarlrMeta } from "/app/apps/jackpotrabbit/pages/email-verify.vue?macro=true";
import { default as gamePcdfn76FCtMeta } from "/app/apps/jackpotrabbit/pages/game.vue?macro=true";
import { default as _91pageName_932qOFo8SqilMeta } from "/app/apps/jackpotrabbit/pages/games/[pageName].vue?macro=true";
import { default as how_45it_45worksFcefYhY61wMeta } from "/app/apps/jackpotrabbit/pages/how-it-works.vue?macro=true";
import { default as indexiqnackB9ViMeta } from "/app/apps/jackpotrabbit/pages/index.vue?macro=true";
import { default as _91pageName_93aKvyxr5fUfMeta } from "/app/apps/jackpotrabbit/pages/issues/[pageName].vue?macro=true";
import { default as collectionsMbdyzzs3KgMeta } from "/app/apps/jackpotrabbit/pages/issues/collections.vue?macro=true";
import { default as _91name_93fGYKPZZVkYMeta } from "/app/apps/jackpotrabbit/pages/page/[name].vue?macro=true";
import { default as phone_45editkZUuHEBDLiMeta } from "/app/apps/jackpotrabbit/pages/phone-edit.vue?macro=true";
import { default as phone_45verifyeIdUoXjODxMeta } from "/app/apps/jackpotrabbit/pages/phone-verify.vue?macro=true";
import { default as piggy_45bankaO153wS5P7Meta } from "/app/apps/jackpotrabbit/pages/piggy-bank.vue?macro=true";
import { default as promotionsJwAoRsHd3DMeta } from "/app/apps/jackpotrabbit/pages/promotions.vue?macro=true";
import { default as referralm4cZA5jaiIMeta } from "/app/apps/jackpotrabbit/pages/referral.vue?macro=true";
import { default as how_45it_45worksNHYcZiVb2bMeta } from "/app/apps/jackpotrabbit/pages/tournaments/how-it-works.vue?macro=true";
import { default as indexeDKeZIzyNgMeta } from "/app/apps/jackpotrabbit/pages/tournaments/index.vue?macro=true";
export default [
  {
    name: indexBwaSHyWdZGMeta?.name ?? "affiliates___en",
    path: indexBwaSHyWdZGMeta?.path ?? "/affiliates",
    meta: indexBwaSHyWdZGMeta || {},
    alias: indexBwaSHyWdZGMeta?.alias || [],
    redirect: indexBwaSHyWdZGMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/affiliates/index.vue").then(m => m.default || m)
  },
  {
    name: partners_45accountjEX9zL1gx1Meta?.name ?? "affiliates-partners-account___en",
    path: partners_45accountjEX9zL1gx1Meta?.path ?? "/affiliates/partners-account",
    meta: partners_45accountjEX9zL1gx1Meta || {},
    alias: partners_45accountjEX9zL1gx1Meta?.alias || [],
    redirect: partners_45accountjEX9zL1gx1Meta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/affiliates/partners-account.vue").then(m => m.default || m)
  },
  {
    name: change_45passworduRBA0gBkFoMeta?.name ?? "change-password___en",
    path: change_45passworduRBA0gBkFoMeta?.path ?? "/change-password",
    meta: change_45passworduRBA0gBkFoMeta || {},
    alias: change_45passworduRBA0gBkFoMeta?.alias || [],
    redirect: change_45passworduRBA0gBkFoMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/change-password.vue").then(m => m.default || m)
  },
  {
    name: complete_45profileGfM2e59G1pMeta?.name ?? "complete-profile___en",
    path: complete_45profileGfM2e59G1pMeta?.path ?? "/complete-profile",
    meta: complete_45profileGfM2e59G1pMeta || {},
    alias: complete_45profileGfM2e59G1pMeta?.alias || [],
    redirect: complete_45profileGfM2e59G1pMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/complete-profile.vue").then(m => m.default || m)
  },
  {
    name: email_45editq3VahIHZKXMeta?.name ?? "email-edit___en",
    path: email_45editq3VahIHZKXMeta?.path ?? "/email-edit",
    meta: email_45editq3VahIHZKXMeta || {},
    alias: email_45editq3VahIHZKXMeta?.alias || [],
    redirect: email_45editq3VahIHZKXMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/email-edit.vue").then(m => m.default || m)
  },
  {
    name: email_45verifyOs4zgQarlrMeta?.name ?? "email-verify___en",
    path: email_45verifyOs4zgQarlrMeta?.path ?? "/email-verify",
    meta: email_45verifyOs4zgQarlrMeta || {},
    alias: email_45verifyOs4zgQarlrMeta?.alias || [],
    redirect: email_45verifyOs4zgQarlrMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/email-verify.vue").then(m => m.default || m)
  },
  {
    name: gamePcdfn76FCtMeta?.name ?? "game___en",
    path: gamePcdfn76FCtMeta?.path ?? "/game",
    meta: gamePcdfn76FCtMeta || {},
    alias: gamePcdfn76FCtMeta?.alias || [],
    redirect: gamePcdfn76FCtMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/game.vue").then(m => m.default || m)
  },
  {
    name: _91pageName_932qOFo8SqilMeta?.name ?? "games-pageName___en",
    path: _91pageName_932qOFo8SqilMeta?.path ?? "/games/:pageName()",
    meta: _91pageName_932qOFo8SqilMeta || {},
    alias: _91pageName_932qOFo8SqilMeta?.alias || [],
    redirect: _91pageName_932qOFo8SqilMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/games/[pageName].vue").then(m => m.default || m)
  },
  {
    name: how_45it_45worksFcefYhY61wMeta?.name ?? "how-it-works___en",
    path: how_45it_45worksFcefYhY61wMeta?.path ?? "/how-it-works",
    meta: how_45it_45worksFcefYhY61wMeta || {},
    alias: how_45it_45worksFcefYhY61wMeta?.alias || [],
    redirect: how_45it_45worksFcefYhY61wMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: indexiqnackB9ViMeta?.name ?? "index___en",
    path: indexiqnackB9ViMeta?.path ?? "/",
    meta: indexiqnackB9ViMeta || {},
    alias: indexiqnackB9ViMeta?.alias || [],
    redirect: indexiqnackB9ViMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91pageName_93aKvyxr5fUfMeta?.name ?? "issues-pageName___en",
    path: _91pageName_93aKvyxr5fUfMeta?.path ?? "/issues/:pageName()",
    meta: _91pageName_93aKvyxr5fUfMeta || {},
    alias: _91pageName_93aKvyxr5fUfMeta?.alias || [],
    redirect: _91pageName_93aKvyxr5fUfMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/issues/[pageName].vue").then(m => m.default || m)
  },
  {
    name: collectionsMbdyzzs3KgMeta?.name ?? "issues-collections___en",
    path: collectionsMbdyzzs3KgMeta?.path ?? "/issues/collections",
    meta: collectionsMbdyzzs3KgMeta || {},
    alias: collectionsMbdyzzs3KgMeta?.alias || [],
    redirect: collectionsMbdyzzs3KgMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/issues/collections.vue").then(m => m.default || m)
  },
  {
    name: _91name_93fGYKPZZVkYMeta?.name ?? "page-name___en",
    path: _91name_93fGYKPZZVkYMeta?.path ?? "/page/:name()",
    meta: _91name_93fGYKPZZVkYMeta || {},
    alias: _91name_93fGYKPZZVkYMeta?.alias || [],
    redirect: _91name_93fGYKPZZVkYMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/page/[name].vue").then(m => m.default || m)
  },
  {
    name: phone_45editkZUuHEBDLiMeta?.name ?? "phone-edit___en",
    path: phone_45editkZUuHEBDLiMeta?.path ?? "/phone-edit",
    meta: phone_45editkZUuHEBDLiMeta || {},
    alias: phone_45editkZUuHEBDLiMeta?.alias || [],
    redirect: phone_45editkZUuHEBDLiMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/phone-edit.vue").then(m => m.default || m)
  },
  {
    name: phone_45verifyeIdUoXjODxMeta?.name ?? "phone-verify___en",
    path: phone_45verifyeIdUoXjODxMeta?.path ?? "/phone-verify",
    meta: phone_45verifyeIdUoXjODxMeta || {},
    alias: phone_45verifyeIdUoXjODxMeta?.alias || [],
    redirect: phone_45verifyeIdUoXjODxMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/phone-verify.vue").then(m => m.default || m)
  },
  {
    name: piggy_45bankaO153wS5P7Meta?.name ?? "piggy-bank___en",
    path: piggy_45bankaO153wS5P7Meta?.path ?? "/piggy-bank",
    meta: piggy_45bankaO153wS5P7Meta || {},
    alias: piggy_45bankaO153wS5P7Meta?.alias || [],
    redirect: piggy_45bankaO153wS5P7Meta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/piggy-bank.vue").then(m => m.default || m)
  },
  {
    name: promotionsJwAoRsHd3DMeta?.name ?? "promotions___en",
    path: promotionsJwAoRsHd3DMeta?.path ?? "/promotions",
    meta: promotionsJwAoRsHd3DMeta || {},
    alias: promotionsJwAoRsHd3DMeta?.alias || [],
    redirect: promotionsJwAoRsHd3DMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/promotions.vue").then(m => m.default || m)
  },
  {
    name: referralm4cZA5jaiIMeta?.name ?? "referral___en",
    path: referralm4cZA5jaiIMeta?.path ?? "/referral",
    meta: referralm4cZA5jaiIMeta || {},
    alias: referralm4cZA5jaiIMeta?.alias || [],
    redirect: referralm4cZA5jaiIMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: how_45it_45worksNHYcZiVb2bMeta?.name ?? "tournaments-how-it-works___en",
    path: how_45it_45worksNHYcZiVb2bMeta?.path ?? "/tournaments/how-it-works",
    meta: how_45it_45worksNHYcZiVb2bMeta || {},
    alias: how_45it_45worksNHYcZiVb2bMeta?.alias || [],
    redirect: how_45it_45worksNHYcZiVb2bMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/tournaments/how-it-works.vue").then(m => m.default || m)
  },
  {
    name: indexeDKeZIzyNgMeta?.name ?? "tournaments___en",
    path: indexeDKeZIzyNgMeta?.path ?? "/tournaments",
    meta: indexeDKeZIzyNgMeta || {},
    alias: indexeDKeZIzyNgMeta?.alias || [],
    redirect: indexeDKeZIzyNgMeta?.redirect,
    component: () => import("/app/apps/jackpotrabbit/pages/tournaments/index.vue").then(m => m.default || m)
  }
]