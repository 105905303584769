const useHomeData = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const { data: appInit } = useAppInitData();
	const { isLimitOffer } = useFlipTheCoin();
	const { select } = useGamesState();

	const {
		data: pageData,
		pending: loading,
		refresh
	} = useAsyncFetch({
		path: "/rest/page/home/",
		method: "get",
		options: {
			transform: (data) => {
				const jackpotGames = data.payload?.games?.issues?.jackpot?.games || [];

				const newGames = data.payload?.games?.issues?.["new-games"]?.games || [];

				if (!appInit.value?.isGuest && appInit.value?.bannerPresetPackage) {
					data.payload?.banners?.unshift({
						type: "bannerPresetPackage",
						...appInit.value.bannerPresetPackage
					});
				}
				return { ...data, newGames, jackpotGames };
			},
			watch: [() => appInit.value?.isGuest],
			cached: true,
			server: true,
			immediate
		}
	});

	const { data: recentWinners } = useAsyncFetch({
		path: "/rest/players-activity/",
		method: "get",
		fetchOptions: {
			query: { limit: 6 }
		},
		options: {
			cached: true
		}
	});

	const { games: allGamesResponse } = useGetGameFiltersData();

	const makeTwoRowsTable = <T>(arr: T[]): T[][] =>
		arr.reduce((result, current, index, array) => {
			if (index % 2 === 0) {
				result.push(array.slice(index, index + 2) as T[]);
			}
			return result;
		}, [] as T[][]);

	const seoData = computed(() => pageData.value?.seo);

	const packages = computed(() => pageData.value?.payload?.packages);

	const lastGames = computed(() => select(appInit.value?.lastGames || []));

	const issues = computed(() => pageData.value?.payload?.games?.issues);

	const jackpotGames = computed(() => pageData.value?.payload?.games?.issues?.jackpot?.games || []);

	const holdNLinksGames = computed(() => pageData.value?.payload?.games?.issues?.["hold-n-link"]?.games || []);

	const promotions = computed(() => pageData.value?.payload?.promotions || []);

	const gameOfWeek = computed(() => pageData.value?.payload?.games?.gameOfWeek);

	const gamesWithWeekGame = computed(() => pageData.value?.payload?.games?.issues?.["popular-games"]);

	const popularGames = computed(() => pageData.value?.payload?.games?.issues?.["popular-games"]?.games || []);

	const hotGames = computed(() => pageData.value?.payload?.games?.issues?.["hot-games"]?.games || []);

	const allGamesIssues = computed(() => pageData.value?.payload?.games?.issues?.["all-games"]?.games || []);

	const collections = computed(() => pageData.value?.payload?.games?.collections || []);

	const favoritesSet = computed(() => new Set(appInit.value?.favoriteGames || []));

	const allGames = computed(() => {
		const gamesArray = allGamesResponse.value || [];
		return gamesArray.map((game) => ({
			...game,
			isFavorite: game.id ? favoritesSet.value.has(game.id) : false
		}));
	});

	const yourPick = computed(() => {
		const reversedFavGames = [...(appInit.value?.favoriteGames || "")].reverse();
		const uniqueGames = [...new Set([...reversedFavGames])];

		return uniqueGames.map((id) => allGames.value?.find((g: { id?: number }) => g.id === id)).filter(Boolean);
	});

	const holdNLinksGamesArrayable = computed(() => makeTwoRowsTable(holdNLinksGames.value));
	const yourPickGamesArrayable = computed(() => makeTwoRowsTable(yourPick.value));
	const jackpotGamesArrayable = computed(() => makeTwoRowsTable(jackpotGames.value));

	const questData = computed(() => pageData.value?.payload?.questData);

	const lastWinners = computed(() => pageData.value?.payload?.lastWinners || null);

	const banners = computed(
		() =>
			pageData.value?.payload?.banners?.filter(({ type }) => {
				if (type === "quest") {
					return !!questData.value?.quest;
				}

				return type !== "flipTheCoin" || !isLimitOffer.value;
			}) || []
	);

	return {
		loading,
		pageData,
		packages,
		gameOfWeek,
		allGames,
		allGamesIssues,
		collections,
		issues,
		lastGames,
		refresh,
		jackpotGames,
		holdNLinksGames,
		holdNLinksGamesArrayable,
		yourPickGamesArrayable,
		jackpotGamesArrayable,
		promotions,
		gamesWithWeekGame,
		popularGames,
		hotGames,
		yourPick,
		banners,
		recentWinners,
		questData,
		lastWinners,
		seoData
	};
};

export default useHomeData;
